var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.$root.$data.me.Username == "erikbas" && false
                ? _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.sendMail(
                                "/users/B1EB51E1-2342-462B-9462-A6DE266075A0/send-migration-email"
                              )
                            }
                          }
                        },
                        [_vm._v("Stuur mail")]
                      ),
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.sendMail(
                                "/users/send-all-migration-emails?perPage=" +
                                  _vm.perpage
                              )
                            }
                          }
                        },
                        [_vm._v("Stuur ALLE mail")]
                      ),
                      _c("b-input", {
                        model: {
                          value: _vm.perpage,
                          callback: function($$v) {
                            _vm.perpage = $$v
                          },
                          expression: "perpage"
                        }
                      }),
                      _c("pre", {
                        domProps: { innerHTML: _vm._s(_vm.results) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("page-snippet", { attrs: { name: "dashboard-welcome" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Je recente releases" } },
                [
                  _c("release-widget", {
                    attrs: {
                      sortBy: "ReleaseDate",
                      sortDesc: true,
                      filter: {
                        ReleaseDate: { value: _vm.dateNow(), compare: "lt" }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Je aankomende releases" } },
                [
                  _c("release-widget", {
                    attrs: {
                      sortBy: "ReleaseDate",
                      sortDesc: false,
                      filter: {
                        ReleaseDate: { value: _vm.dateNow(), compare: "gte" }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "6" } },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: 25,
                  aggName: "tracks",
                  title: "Top Tracks",
                  subTitle: "Streams afgelopen 7 dagen"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }