<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div v-if="$root.$data.me.Username == 'erikbas' && false">
          <b-button @click="sendMail('/users/B1EB51E1-2342-462B-9462-A6DE266075A0/send-migration-email')">Stuur mail</b-button>
          <b-button @click="sendMail('/users/send-all-migration-emails?perPage=' + perpage)">Stuur ALLE mail</b-button>
          <b-input v-model="perpage" />

          <pre v-html="results" />
        </div>
        
        <page-snippet name="dashboard-welcome" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" md="6" cols="12" class="mt-4">
        <b-card header="Je recente releases">
          <release-widget 
              sortBy="ReleaseDate"
              :sortDesc="true"
              :filter="{ ReleaseDate: { value: dateNow(), compare: 'lt' } }"
            />
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="12" class="mt-4">
        <b-card header="Je aankomende releases">
          <release-widget 
              sortBy="ReleaseDate"
              :sortDesc="false"
              :filter="{ ReleaseDate: { value: dateNow(), compare: 'gte' } }"
            />        
        </b-card>
      </b-col>

      <b-col lg="4" md="6" cols="6" class="mt-4">
        <TrendList :trendFilter="trendFilter" :perPage="25" aggName="tracks" title="Top Tracks" subTitle="Streams afgelopen 7 dagen" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import PageSnippet from '@/components/PageSnippet'
  import moment from 'moment'

  export default {
    name: 'Dashboard',
    components: {
      PageSnippet,
      'release-widget': () => import('@/components/ReleaseWidget'),
      'TrendList': () => import('@/components/TrendList.vue'),
    },
    props: {

    },
    data () {
      return {
        perpage: 1,
        results: '',
        trendFilter: {
          timerange: '7d',
          dateFrom: '',
          dateTo: '',
          eventType: 'stream',
          selectedTracks: [],
          selectedAlbums: [],
          selectedAttributions: [],
        }
      }
    },
    methods: {
      dateNow () {
        return moment().format('YYYY-MM-DD')
      },
      sendMail (param) {
      this.$http.put(param)
        .then(response => {
          this.results = response.data
        })
      },
    },
    mounted () {

    }
  }
</script>

<style scoped>

</style>
